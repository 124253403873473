import React, { useEffect } from 'react';
import HomeRouter from 'src/router/HomeRouter';
import {
  Drawer,
  List,
  ListItem,
  Hidden,
  IconButton,
  Typography,
  Button,
  Divider,
  Dialog,
  Slide,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ClickCollectIcon from '../../assets/click_collect.svg';
import OrdersIcon from '../../assets/orders.svg';
import AnalyticsIcon from '../../assets/analytics.svg';
import ScanIcon from '../../assets/scan.svg';
import VerificationIcon from '../../assets/verification.svg';
import CheckIcon from '../../assets/checkIcon.svg';
import MonitorIcon from '../../assets/monitor_outline.svg';
import HelpAndSupportIcon from '../../assets/help.svg';
import UserIcon from '../../assets/user.svg';
import PriceTag from '../../assets/pricetag.svg';
import Smartphone from '../../assets/smartphone.svg';
import { LogoutContext } from 'src/components/LogoutWrapper';
import { useContext } from 'react';
import ArrowDownIcon from 'src/assets/svgIcons/ArrowDownIcon';
import { useDispatch, useSelector } from 'react-redux';
import StoreSelector from './components/StoreSelector';
import { TransitionProps } from '@material-ui/core/transitions';
import FilterIcon from 'src/assets/svgIcons/FilterIcon';
import { SelectedStores } from 'src/components/SelectedStores';
import packageInfo from '../../../package.json';
import IntroModal from 'src/components/IntroModal';
import { get, startCase, uniqBy } from 'lodash';
import FoldersIcon from '../../assets/folders.svg';
import CalendarIcon from '../../assets/calendar.svg';
import { datadogRum } from '@datadog/browser-rum';
import { storesApi } from 'src/services/user';
import { useMutation } from 'react-query';
import { useCookies } from 'react-cookie';
import { Alert } from '@material-ui/lab';

const drawerWidth = 350;

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: '100%',
    display: 'flex',
    width: '100vw',
  },
  appBar: {
    [theme.breakpoints.between('md', 'xl')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    width: '100%',
    overflow: 'hidden',
  },
  drawer: {
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    zIndex: -1,
  },
  drawerPermanant: {
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    position: 'absolute',
    left: 16,
    top: 32,
    backgroundColor: theme.palette.color_icon_button.main,
    color: theme.palette.color_white.main,
  },
  list: {
    padding: 0,
    flex: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  storeValue: {
    flex: 1,
    marginRight: 10,
    height: 40,
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(228, 233, 242, 0.2)',
    border: 'solid 1px #e4e9f2',
    textTransform: 'none',
    color: '#8f9bb3',
    fontSize: 15,
    '& .MuiButton-endIcon': {
      position: 'absolute',
      right: 8,
    },
    textOverflow: 'ellipsis',
  },

  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 100,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 2000,
  },
  loaderContainer: {
    height: '100%',
    width: '100%',
    background: '#00000080',
    zIndex: 2000,
    position: 'fixed',
  },
  loaderContent: {
    margin: 'auto',
    height: '100%',
    position: 'relative',
    top: '40%',
  },

  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },

  mobileLoaderContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2000,
  },

  mobileLoader: {
    width: 200,
    height: 100,
    backgroundColor: '#CCCCCC',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  circle: {
    marginRight: 20,
  },

  listItem: {
    height: '64px',
  },
  listItemText: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    fontSize: 15,
  },
  drawerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  selectStore: { marginBottom: 8, fontSize: 13 },
  storeValueContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  refreshButton: {
    width: 40,
    height: 40,
    border: `solid 1px ${theme.palette.primary.main}`,
    backgroundColor: 'rgba(51, 102, 255, 0.1)',
  },
  icon: { width: 20, height: 20 },
  listContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  listContentIcon: { width: 20, height: 20, marginRight: 20 },
  listContentText: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    fontSize: 15,
  },
  signUpContainer: {
    display: 'flex',
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.color_light_grey.main,
    borderRadius: 20,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarIcon: { width: 25, height: 25 },
  userNameContainer: {
    display: 'flex',
    width: '100%',
    marginLeft: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  userNameText: {
    fontSize: 18,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
  },
  logoutButton: { alignSelf: 'flex-start', marginLeft: -8 },
  logoutButtonText: {
    color: theme.palette.color_text_grey.main,
    fontSize: 12,
  },
  marginTop: { marginTop: 20 },
  link: { textDecoration: 'none' },
  iconRoot: {
    '&:hover': {
      backgroundColor: theme.palette.color_icon_button.main,
    },
  },
}));

const Home = () => {
  let hasMLSEOnlyStore = true;
  let hasCartersOnlyStore = true;
  let hasDDFOnlyStore = true;
  const classes = useStyles();
  const context = useContext(LogoutContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const intl = useIntl();
  const [openStoreModal, setOpenStoreModal] = React.useState(false);
  const { active_stores } = useSelector((state: any) => state.store);
  const [cookies] = useCookies();
  const [selectAllStores, setSelectAllStores] = useState(false);
  const {
    userData,
    permissions: { allow_analytics, allow_settlements },
  } = useSelector((state: any) => state.user);
  const { introVisible } = useSelector((state: any) => state.screens);
  let hasClickAndCollectStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.click_and_collect', false),
  );
  let hasKioskStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.kiosk', false),
  );
  let hasMPosStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.mpos', false),
  );

  // TODO update following methods with cash_desk & cash_report keys as per BE implementation (GPP-5295)
  let hasCashDeskStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.cashier_kiosk', false),
  );
  let hasPromoEnabled = active_stores.some((store: any) =>
    get(store, 'features_applicable.promotion', false),
  );
  let hasCashReportStores = active_stores.some((store: any) =>
    get(store, 'features_applicable.cash_desk', true),
  );
  let hasPriceActivationStores = active_stores.some((store: any) =>
    get(
      store,
      'features_applicable.price_activation_link',
      store.features_applicable.price_activation_link?.length > 0,
    ),
  );
  let priceActivationLink = active_stores.find((store: any) => {
    return store.features_applicable.price_activation_link;
  });
  const checkStoreTypes = () => {
    active_stores?.filter((store: any) => {
      if (store?.store_type !== 'MLSEStoreType') {
        hasMLSEOnlyStore = false;
      }
      if (store?.store_type !== 'CartersStoreType') {
        hasCartersOnlyStore = false;
      }
      if (store?.store_type !== 'DubaiDutyFreeStoreType') {
        hasDDFOnlyStore = false;
      }
    });
    return {
      MLSEStoreType: hasMLSEOnlyStore,
      CartersStoreType: hasCartersOnlyStore,
      DubaiDutyFreeStoreType: hasDDFOnlyStore,
    };
  };
  const showRevenueReport =
    checkStoreTypes()['CartersStoreType'] ||
    checkStoreTypes()['MLSEStoreType'] ||
    checkStoreTypes()['DubaiDutyFreeStoreType']
      ? true
      : false;

  const showEmailReport = checkStoreTypes()['CartersStoreType'];
  const showItemReport = checkStoreTypes()['MLSEStoreType'] ? true : false;

  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');

  const menus = [
    {
      title: 'click&Collect',
      selected: true,
      linkTo: '/dashboard/click_and_collect',
      default: 'Click & Colect',
      icon: ClickCollectIcon,
      visible: hasClickAndCollectStores,
    },
    {
      title: 'orders',
      selected: false,
      linkTo: '/dashboard/orders',
      default: 'Scan & Go orders',
      icon: OrdersIcon,
      visible: true,
    },
    {
      title: 'cashDesk',
      selected: false,
      linkTo: '/dashboard/cashDesk',
      default: 'CashDesk',
      icon: MonitorIcon,
      visible: hasCashDeskStores,
    },
    {
      title: 'kiosk',
      selected: false,
      linkTo: '/dashboard/kiosk',
      default: 'Kiosk',
      icon: MonitorIcon,
      visible: hasKioskStores,
    },
    {
      title: 'mPOS',
      selected: false,
      linkTo: '/dashboard/mpos',
      default: 'mPOS',
      icon: Smartphone,
      visible: hasMPosStores,
    },
    {
      title: 'analytics',
      selected: false,
      linkTo: '/dashboard/analytics',
      default: 'Analytics',
      icon: AnalyticsIcon,
      visible: allow_analytics,
    },
    {
      title: 'cashReport',
      selected: false,
      linkTo: '/dashboard/cashReport',
      default: 'Cash Report',
      icon: FoldersIcon,
      visible: hasCashDeskStores,
    },
    {
      title: 'priceActivation',
      selected: false,
      linkTo: '/dashboard/priceActivation',
      default: 'Price Activation',
      icon: CalendarIcon,
      type: 'priceActivation',
      visible: hasPriceActivationStores,
    },
    {
      title: 'scanOrder',
      selected: false,
      linkTo: '/dashboard/scan',
      default: 'Scan',
      icon: ScanIcon,
      visible: true,
    },
    {
      title: 'verification',
      selected: false,
      linkTo: '/dashboard/verification',
      default: 'Verifications',
      icon: VerificationIcon,
      visible: true,
    },
    {
      title: 'promotions',
      selected: false,
      linkTo: '/dashboard/promos',
      default: 'Promotions',
      icon: VerificationIcon,
      visible: hasPromoEnabled,
    },
    {
      title: 'settlements',
      selected: false,
      linkTo: '/dashboard/settlements',
      default: 'Settlements',
      icon: CheckIcon,
      visible: allow_settlements,
    },
    {
      title: 'helpDesk',
      selected: false,
      linkTo: '/dashboard/helpDesk',
      default: 'Help / Support',
      icon: HelpAndSupportIcon,
      type: 'newTab',
      visible: true,
    },
    {
      title: 'report',
      selected: false,
      linkTo: '/dashboard/revenue',
      default: 'Revenue Report',
      icon: FoldersIcon,
      visible: showRevenueReport,
    },
    {
      title: 'report',
      selected: false,
      linkTo: '/dashboard/emailReport',
      default: 'Email Report',
      icon: FoldersIcon,
      visible: showEmailReport,
    },
    {
      title: 'itemReport',
      selected: false,
      linkTo: '/dashboard/itemReport',
      default: 'Item Report',
      icon: FoldersIcon,
      visible: showItemReport,
    },
    {
      title: 'grafana',
      selected: false,
      linkTo: '/dashboard/grafana',
      default: 'Monitoring',
      type: 'grafana',
      icon: HelpAndSupportIcon,
      visible: !!userData?.email?.includes('mishipay'),
    },
  ];

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  useEffect(() => {
    if (localStorage.getItem('persist:root')) {
      localStorage.removeItem('persist:root');
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'sessionExpired' }) + ". Please refresh your screen.");
      setTimeout(() => {
        context.logoutUser();
      }, 4000);
    }
  });
  useEffect(() => {
    let timer: any = null;
    if (success) {
      timer = setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [success]);

  useEffect(() => {
    let timer: any = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
        setErrorMessage('Error');
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  useEffect(() => {
    try {
      let maskedemail;
      if (
        !userData?.email?.includes('mishipay') ||
        !userData?.username?.includes('mishipay')
      ) {
        let email = userData.email.split('@');
        maskedemail = `****@${email[1]}`;
      }
      datadogRum.setUser({
        id: userData.user_id,
        username: userData.username,
        email: maskedemail,
      });
    } catch (e) {
      console.log('missing userdata', e);
    }
  }, [userData]);

  const handleOpenStoreModal = async () => {
    setOpenStoreModal(true);
  };

  const handleCloseStoreModal = (event: object, reason: string) => {
    if (reason !== 'backdropClick') setOpenStoreModal(false);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const lovesNamingConvention = (stores: any = selectAllStores) => {
    if (stores.length > 30) {
      return stores;
    }
    stores?.filter((store: any) => {
      if (store?.store_type === 'LovesStoreType') {
        store = editLovesStoreName(store);
      }
    });
    return stores;
  };

  const editLovesStoreName = (store: any) => {
    if (store.address_json?.length) {
      let address = JSON.parse(store.address_json);
      let clonedObject = { ...address };
      clonedObject = {
        ...clonedObject,
        retailer: `${store.retailer_store_id} - ${address.city}, ${address.state}`,
      };
      return clonedObject;
    }
    return store;
  };
  const refreshStores = () =>
    storesApi.query({ v1: true, token: cookies.app_token, show_demo: true });
  const { isLoading, mutate: getStores } = useMutation(refreshStores, {
    onError: (serverError: any) => {
      setError(true);
      setErrorMessage(serverError);
    },
    onSuccess: successData => {
      let storesData = get(successData, 'data.data', []).filter((item: any) => {
        return !item.retailer.split(' ').includes('All');
      });
      storesData = lovesNamingConvention(storesData);
      let regions = uniqBy(
        storesData
          .map((item: any) => {
            if (item.region) {
              return {
                title: item.region,
                value: item.region,
              };
            }
          })
          .filter((item: any) => item && item.value),
        'value',
      );
      let retailers = uniqBy(
        storesData
          .map((item: any) => {
            if (item.store_type) {
              return {
                title: startCase(item.store_type.replace('StoreType', '')),
                value: item.store_type,
              };
            }
          })
          .filter((item: any) => item && item.value),
        'value',
      );
      dispatch({
        type: 'SET_STORES',
        payload: lovesNamingConvention(storesData),
      });

      dispatch({
        type: 'SET_REGIONS',
        payload: regions,
      });
      dispatch({
        type: 'SET_RETAILERS',
        payload: retailers.sort((a: any, b: any) => {
          a = a.title.toLowerCase();
          b = b.title.toLowerCase();
          return a < b ? -1 : a > b ? 1 : 0;
        }),
      });
    },
  });

  const selectedTabContent = (item: any, index: any) => {
    switch (item.type) {
      case 'grafana': {
        return (
          <Link key={index} to={item.linkTo} className={classes.link}>
            <ListItem button key={index} className={classes.listItem}>
              <Box className={classes.listContentContainer}>
                <img className={classes.listContentIcon} src={item.icon} />
                <Typography className={classes.listContentText}>
                  <FormattedMessage
                    id={item.title}
                    defaultMessage={item.default}
                  />
                </Typography>
              </Box>
            </ListItem>
            <Divider light />
          </Link>
        );
      }
      case 'newTab': {
        return (
          <>
            <ListItem
              button
              key={index}
              className={classes.listItem}
              onClick={() => {
                if (item.type === 'newTab') {
                  window.open(
                    'https://mishipay.atlassian.net/servicedesk/customer/portals',
                    '_blank',
                  );
                }
              }}
            >
              <Box className={classes.listContentContainer}>
                <img className={classes.listContentIcon} src={item.icon} />
                <Typography className={classes.listContentText}>
                  <FormattedMessage
                    id={item.title}
                    defaultMessage={item.default}
                  ></FormattedMessage>
                </Typography>
              </Box>
            </ListItem>
            <Divider light />
          </>
        );
      }
      case 'priceActivation': {
        return (
          <>
            <ListItem
              button
              key={index}
              className={classes.listItem}
              onClick={() => {
                if (item.type === 'priceActivation') {
                  window.open(
                    priceActivationLink.features_applicable
                      .price_activation_link,
                    '_blank',
                  );
                }
              }}
            >
              <Box className={classes.listContentContainer}>
                <img className={classes.listContentIcon} src={item.icon} />
                <Typography className={classes.listContentText}>
                  <FormattedMessage
                    id={item.title}
                    defaultMessage={item.default}
                  ></FormattedMessage>
                </Typography>
              </Box>
            </ListItem>
            <Divider light />
          </>
        );
      }
      default: {
        return (
          <Link key={index} to={item.linkTo} className={classes.link}>
            <ListItem button key={index} className={classes.listItem}>
              <Box className={classes.listContentContainer}>
                <img className={classes.listContentIcon} src={item.icon} />
                <Typography className={classes.listContentText}>
                  <FormattedMessage
                    id={item.title}
                    defaultMessage={item.default}
                  />
                </Typography>
              </Box>
            </ListItem>
            <Divider light />
          </Link>
        );
      }
    }
  };

  const drawerContent = () => (
    <>
      <Box className={classes.drawerContainer}>
        <Typography variant="subtitle2" className={classes.selectStore}>
          Select Region, Retailer and Store
        </Typography>

        <Box className={classes.storeValueContainer}>
          <Button
            variant="outlined"
            className={classes.storeValue}
            onClick={handleOpenStoreModal}
            endIcon={<ArrowDownIcon />}
            startIcon={<FilterIcon />}
            style={{ marginBottom: 4 }}
          >
            SELECT
          </Button>
          <SelectedStores />
          {/* <Button onClick={() => getStores()} className={classes.refreshButton}>
            <img className={classes.icon} src={RefreshIcon} alt="refresh" />
          </Button> */}
        </Box>
      </Box>
      <Divider className={classes.marginTop} light />
      <List className={classes.list}>
        {menus.map((item: any, index: number) => (
          <React.Fragment key={index}>
            {item?.visible ? selectedTabContent(item, index) : <></>}
          </React.Fragment>
        ))}
      </List>
      <Typography
        variant="caption"
        style={{ alignSelf: 'flex-start', marginLeft: 16 }}
      >
        {process.env.REACT_APP_ENV_TYPE} v{packageInfo.version}
      </Typography>

      <Divider light />

      <Box className={classes.signUpContainer}>
        <Box component="div" className={classes.avatar}>
          <img className={classes.avatarIcon} src={UserIcon} alt="user" />
        </Box>
        <Box component="div" className={classes.userNameContainer}>
          <Typography className={classes.userNameText}>
            {userData.username}
          </Typography>
          <Button
            className={classes.logoutButton}
            onClick={() => {
              context.logoutUser();
            }}
          >
            <Typography className={classes.logoutButtonText}>
              {intl.formatMessage({ id: 'signOut' }).toUpperCase()}
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
  return (
    <>
      {error && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Box>
      )}
      <Box className={classes.root}>
        {spinner && (
          <Box className={classes.loaderContainer}>
            <Box className={classes.loaderContent}>
              <CircularProgress color="primary" size={50} />
            </Box>
          </Box>
        )}
        <Hidden only={['md', 'lg', 'xl']} implementation="css">
          <Drawer
            variant="temporary"
            className={classes.drawer}
            classes={{ paper: classes.drawer }}
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
          >
            {drawerContent()}
          </Drawer>
        </Hidden>
        <Hidden only={['xs', 'sm']} implementation="css">
          <Drawer
            variant="permanent"
            className={classes.drawerPermanant}
            classes={{ paper: classes.drawerPermanant }}
            anchor="left"
          >
            {drawerContent()}
          </Drawer>
        </Hidden>
        <Box className={classes.appBar}>
          <Hidden only={['md', 'lg', 'xl']} implementation="css">
            <IconButton
              className={classes.menu}
              classes={{ root: classes.iconRoot }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <HomeRouter />
        </Box>
      </Box>
      {openStoreModal && (
        <Dialog
          keepMounted
          disableEnforceFocus
          disableEscapeKeyDown
          open={openStoreModal}
          TransitionComponent={Transition}
          scroll="paper"
          onClose={handleCloseStoreModal}
          fullWidth={true}
        >
          <StoreSelector onClose={handleCloseStoreModal} />
        </Dialog>
      )}
      {introVisible && (
        <Dialog
          disableEnforceFocus
          disableEscapeKeyDown
          open={introVisible}
          TransitionComponent={Transition}
          scroll="paper"
          onClose={() => {
            dispatch({
              type: 'TOGGLE_INTRO',
              payload: false,
            });
          }}
          fullWidth={true}
        >
          <IntroModal
            onClose={() => {
              dispatch({
                type: 'TOGGLE_INTRO',
                payload: false,
              });
            }}
          />
        </Dialog>
      )}
    </>
  );
};

export default Home;
